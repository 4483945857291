import { FCC } from '~/core/@types/global'

interface LayoutHybridProps {
  background?: string
}

const LayoutHybrid: FCC<LayoutHybridProps> = (props) => {
  const { children, background } = props

  return <div className={background}>{children}</div>
}

export default LayoutHybrid
