import { Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useEffect } from 'react'
import HeadMetaTags from '~/components/HeadMetaTags'
import LayoutHybrid from '~/components/Layout/LayoutHybrid'
import configuration from '~/configuration'
import { PUBLIC_APP_NAME } from '~/core/constants/env'
import { Button } from '~/core/ui/Button'
import { Container } from '~/core/ui/Container'
import { withTranslationProps } from '~/lib/next/with-translation'

function Error404Page() {
  const { t } = useTranslation('common')

  useEffect(() => {
    if (window) {
      window.addEventListener('popstate', function (e) {
        window.location.href = configuration.path.default
      })
    }
  }, [])

  return (
    <>
      <HeadMetaTags title={`${t('seo:404', { PUBLIC_APP_NAME })}`} />

      <LayoutHybrid>
        <Container overrideClass="max-w-[375px] tablet:max-w-[392px] min-h-screen px-4 tablet:px-0">
          <div className="flex min-h-screen flex-col">
            <div className="mb-5 mt-6 h-5 tablet:mt-10" />

            <div className="flex shrink-0 grow flex-col items-center justify-center">
              <svg
                width="113"
                height="112"
                viewBox="0 0 113 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle opacity=".5" cx="56.5" cy="56" r="56" fill="#EDEFFF" />
                <circle opacity=".6" cx="56.5" cy="56" r="44" fill="#DFE3FF" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M46.806 34.586A2 2 0 0 1 48.22 34h16.56a2 2 0 0 1 1.414.586l11.72 11.72a2 2 0 0 1 .586 1.414v16.56a2 2 0 0 1-.586 1.414l-11.72 11.72A2 2 0 0 1 64.78 78H48.22a2 2 0 0 1-1.414-.586l-11.72-11.72a2 2 0 0 1-.586-1.414V47.72a2 2 0 0 1 .586-1.414l11.72-11.72zM49.048 38 38.5 48.548v14.904L49.048 74h14.904L74.5 63.452V48.548L63.952 38H49.048zm14.866 10.586a2 2 0 0 1 0 2.828L59.328 56l4.586 4.586a2 2 0 1 1-2.828 2.828L56.5 58.828l-4.586 4.586a2 2 0 1 1-2.828-2.828L53.672 56l-4.586-4.586a2 2 0 1 1 2.828-2.828l4.586 4.586 4.586-4.586a2 2 0 0 1 2.828 0z"
                  fill="#5D5AF9"
                />
              </svg>

              <div className="my-[44px]">
                <div className="text-center">
                  <b className="text-2xl font-semibold text-gray-900 tablet:text-3xl">
                    {t('error.404.title')}
                  </b>
                </div>

                <div className="mt-5">
                  <p className="text-center text-base text-gray-900">
                    <Trans i18nKey={'common:error:404:description'}>
                      <a
                        href={`mailto:${configuration.mailto}`}
                        className="underline"
                      />
                    </Trans>
                  </p>
                </div>
              </div>

              <Link className="w-full" href={configuration.path.default}>
                <Button
                  size="lg"
                  type="primary"
                  className="w-full"
                  label={`${t('error.404.button')}`}
                />
              </Link>
            </div>

            <div className="mb-6 mt-5 flex h-5 w-full items-center justify-center space-x-8 tablet:mb-10" />
          </div>
        </Container>
      </LayoutHybrid>
    </>
  )
}

export async function getStaticProps() {
  const { props } = await withTranslationProps()

  return {
    props
  }
}

export default Error404Page
